<mat-dialog-content>
    <div class="row">
        <div class="col content">
            <h2>You have gone into offline mode</h2>
            <h3>Don't worry, you can still continue your exam as normal</h3>
            <ul>
                <li>
                    Don't try and refresh the page.
                </li>
                <li>
                    Continue answering questions, your exam time continues. 
                </li>
                <li>
                    At the end of your exam, you will be asked to upload your final answers.
                </li>
            </ul>
            <h3>If you reconnect to the internet during the exam, we will save the answers for you automatically, you don't have to do anything.</h3>
        </div>
    </div>
    <div class="row button-content">
        <div class="col-2">
            <button mat-raised-button class="tbp" style="width: 6rem;" (click)="close()">OK</button>
        </div>
    </div>
</mat-dialog-content>
