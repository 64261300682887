import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationError } from '../../model/error';

export enum ErrorCode {
	None = 0,
	Unknown = 1,
	ServerUnavailable = 2,
	ServerTimeout = 3,
	InvalidAccessToken = 10000,
	ExamHasEnded,
	PreviousExamPresentOnDevice,
	ExamNotStarted,
	CandidateExamNotStarted,
	MismatchingExamCandidateQuestion,
	MismatchingExamCandidateQuestionAnswer,
	InvalidExamState,
	UploadInvalidChecksum,
	UploadIncorrectPin,
	UploadInvalidPin,
	CandidateNotReady,
	ExamNotAvailable,
	CandidateExamEnded,
	AssetFail,
	ExamPaused
}

@Injectable({
	providedIn: 'root'
})
export class ApplicationErrorHandler {

	constructor(private router: Router) { }

	private readonly errors: ApplicationError[] = [{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Server Error',
		errorCode: ErrorCode.None,
		actionText: 'Troubleshoot',
		action: () => {
			this.router.navigateByUrl('troubleshoot');
		},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Unknown Error',
		errorCode: ErrorCode.Unknown,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Server Unavailable',
		errorCode: ErrorCode.ServerUnavailable,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Server Timeout',
		errorCode: ErrorCode.ServerTimeout,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	},
	{
		technicalName: 'Invalid or missing Access Token',
		friendlyName: 'It looks like you aren\'t logged in. Please log in.',
		errorCode: ErrorCode.InvalidAccessToken,
		actionText: 'Ok',
		action: () => {
			sessionStorage.removeItem('AccessToken');
			this.router.navigateByUrl('login');
		},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Exam has ended',
		errorCode: ErrorCode.ExamHasEnded,
		actionText: 'Need to upload?',
		action: () => {
			this.router.navigateByUrl('upload');
		},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Unknown Error',
		errorCode: ErrorCode.PreviousExamPresentOnDevice,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	},
	{
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Exam not started',
		errorCode: ErrorCode.ExamNotStarted,
		actionText: 'Go To Exam Start',
		action: () => {
			this.router.navigateByUrl('exam/start');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Candidate exam not started',
		errorCode: ErrorCode.CandidateExamNotStarted,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Unknown Error',
		errorCode: ErrorCode.MismatchingExamCandidateQuestion,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Unknown Error',
		errorCode: ErrorCode.MismatchingExamCandidateQuestionAnswer,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Invalid exam state, please contact Highfield',
		errorCode: ErrorCode.InvalidExamState,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Invalid Checksum',
		errorCode: ErrorCode.UploadInvalidChecksum,
		actionText: "Go to Upload",
		action: () => {
			this.router.navigateByUrl('upload');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Unable to find candidate',
		errorCode: ErrorCode.UploadInvalidPin,
		actionText: "Go to Upload",
		action: () => {
			this.router.navigateByUrl('upload');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'The error code is either invalid or nonexistant',
		friendlyName: 'Incorrect PIN',
		errorCode: ErrorCode.UploadIncorrectPin,
		actionText: "Go to Upload",
		action: () => {
			this.router.navigateByUrl('upload');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'Exam Candidate not set to ready',
		friendlyName: 'Please let your invigilator know that you are ready to start the exam',
		errorCode: ErrorCode.CandidateNotReady,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	}, {
		technicalName: 'Exam Not Available',
		friendlyName: 'Your exam is not available',
		errorCode: ErrorCode.ExamNotAvailable,
		actionText: 'Exit',
		action: () => {
			this.router.navigateByUrl('');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'Candidate exam has been ended',
		friendlyName: 'This exam has been ended, and can no longer accept uploaded answers',
		errorCode: ErrorCode.CandidateExamEnded,
		actionText: 'Exit',
		action: () => {
			this.router.navigateByUrl('');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'They can\'t fetch the assets. Something is funky with their internet. ',
		friendlyName: 'Unable to load exam assets, please contact Highfield.',
		errorCode: ErrorCode.AssetFail,
		actionText: 'Troubleshoot',
		action: () => {
			this.router.navigateByUrl('troubleshoot');
		},
		triggerActionImmediately: false
	}, {
		technicalName: 'The exam has been paused, however they did not get the message.',
		friendlyName: 'Your exam has been paused.',
		errorCode: ErrorCode.ExamPaused,
		actionText: 'Continue',
		action: () => {
			this.router.navigateByUrl('exam/paused');
		},
		triggerActionImmediately: true
		}];

	public getErrorByErrorIdentifier(errorCode: number): ApplicationError {
		const error = this.errors.find(x => x.errorCode === errorCode);
		return error || this.errors[0];
	}
}
