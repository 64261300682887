import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponse } from '../../model/request/APIResponse';
import { LoginResponseDto } from '../../model/request/LoginResponseDto';
import { AuthRequestService } from '../request/auth-request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {

  constructor(private authRequestService: AuthRequestService) { }

  public setAccessToken(accessToken: string): void {
	  sessionStorage.setItem('AccessToken', accessToken);
  }

  public getAccessToken(): string {
	  return sessionStorage.getItem('AccessToken')!;
  }

  public clearAccessToken(): void {
	  sessionStorage.removeItem('AccessToken');
  }

  public getAuthHeader(): HttpHeaders {
    const accessToken: string = this.getAccessToken();

    if (!(!!accessToken)) { return new HttpHeaders(); }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', accessToken);
    headers = headers.append('timeout', '30000');

    return headers;
  }

	public attemptPinLogin(pin: string): Observable<APIResponse<LoginResponseDto>> {
		return this.authRequestService.post('auth/candidate/pin', { pin });
	}
	public attemptCredentialLogin(firstName: string, surname: string, dob: Date,): Observable<APIResponse<LoginResponseDto>> {
		return this.authRequestService.post('auth/candidate/credentials', { firstName: firstName, surname: surname, dateOfBirth: dob });
	}
}
