import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationError } from '../../model/error';
import { ErrorDialogData } from '../../model/error-dialog-data';
import { ConnectionErrorModalComponent } from '../../site/error/connection-error-modal/connection-error-modal.component';
import { ErrorModalComponent } from '../../site/error/error-modal/error-modal.component';
import { ApplicationErrorHandler } from './application-error-handler';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private router: Router,
              private applicationErrorHandler: ApplicationErrorHandler,
              private dialog: MatDialog) { }


	public goToErrorPage(errorCode: number, sentryId?: string): void {
	  this.router.navigate([`/error/${errorCode}`], { queryParams: { id: sentryId } });

  }

	public showErrorModal(errorCode: number, sentryId?: string): MatDialogRef<ErrorModalComponent, any> | null {

    if (this.triggerErrorActionImmediately(errorCode)) {
      this.errorSilently(errorCode);
      return null;
    }

    const dialogData: ErrorDialogData = {
        errorCode,
        sentryId
    };

    return this.dialog.open(ErrorModalComponent, {
        width: '30rem',
        data: dialogData,
				panelClass: ['mbg', 'fd']
    });
  }

  public errorSilently(errorCode: number): void {
    const error = this.getError(errorCode);
    if (error.action) {
        error.action();
    }
  }

  public showConnectionErrorModal(): void {
    const dialogRef = this.dialog.open(ConnectionErrorModalComponent, {
      width: '40rem',
      panelClass: ['mbg', 'fd']
    });
  }

	private getError(errorCode: number): ApplicationError {
      return this.applicationErrorHandler.getErrorByErrorIdentifier(errorCode);
  }

  private triggerErrorActionImmediately(errorCode: number): boolean {
    const error = this.getError(errorCode);

    return error.triggerActionImmediately;
  }
}
