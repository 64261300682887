import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIResponse } from '../../model/request/APIResponse';
import { ErrorCode } from '../error/application-error-handler';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {

  constructor(private http: HttpClient,
              @Inject('servicesBaseUrl') private servicesBaseUrl: string,
              private errorService: ErrorService) {
      this.processResponse = this.processResponse.bind(this);
   }

  public get<T>(url: string): Observable<APIResponse<T>> {
    return this.http.get<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`)
                            .pipe(map(this.processResponse));
  }

  public post<T>(url: string, body: any): Observable<APIResponse<T>> {
    return this.http.post<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body)
                            .pipe(map(this.processResponse));
  }

  public put<T>(url: string, body: any): Observable<APIResponse<T>> {
    return this.http.put<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body)
                            .pipe(map(this.processResponse));
  }

  public delete<T>(url: string): Observable<APIResponse<T>> {
    return this.http.delete<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`)
                            .pipe(map(this.processResponse));
  }

  private processResponse<T>(response: APIResponse<T>): APIResponse<T> {
    if (response.responseCode !== 200) {
      this.errorService.goToErrorPage(response.responseCode, response.responseData as unknown as string);

      if (response.responseData) {
				throw response.responseData as unknown as string;
			}
    }

    return response;
  }
}
