<mat-dialog-content>
  <div class="row h-100">
    <div class="col d-flex justify-content-center align-items-center">
      <div class="row error-container">
        <div class="col d-flex inner-container text-center">
          <span class="material-icons error-icon">error</span>
          <h3>{{ error.friendlyName }}</h3>
          @if (error.action && error.actionText) {
            <button mat-raised-button class="tbp" (click)="triggerErrorAction()">{{ error.actionText}}</button>
          }
          @if (id) {
            <mat-expansion-panel (opened)="technicalDetailsPanelOpen = true" (closed)="technicalDetailsPanelOpen = false" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title class="d-flex align-items-center">
                  Technical Details
                </mat-panel-title>
                <mat-panel-description class="d-flex justify-content-end">
                  <button mat-raised-button class="tbp" (click)="toggleTechnicalDetailsPane()">{{ technicalDetailsPanelOpen ? 'See Less' : 'See More' }}</button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col d-flex justify-content-between copy-area">
                  <code class="code" [innerHTML]="id"></code>
                  <button #tooltip="matTooltip" [matTooltip]="tooltipText" matTooltipPosition="right" mat-raised-button class="tbs" (click)=copy($event) (mouseout)="setTooltipTextDefault()"><mat-icon>content_copy</mat-icon></button>
                </div>
              </div>
            </mat-expansion-panel>
          }
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
