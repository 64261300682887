import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-connection-error-modal',
  templateUrl: './connection-error-modal.component.html',
  styleUrls: ['./connection-error-modal.component.scss']
})
export class ConnectionErrorModalComponent {

  constructor(private dialogRef: MatDialogRef<ConnectionErrorModalComponent>) { }

  public close(): void {
    this.dialogRef.close();
  }
}
